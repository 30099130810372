import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Button, Card, Form, Input, message, Row } from "antd"
import { navigate } from "gatsby-link"

export default function Login() {
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      navigate("/congres")
    }
  }, [])
  
  function handleLogin({ email }) {
    localStorage.setItem("userId", email)
    message.success("Bienvenu ", 2)
    navigate("/congres")
  }
  
  return (
    <div style={{ display: "grid", textAlign: "center" }}>
      <StaticImage
        src="../images/exterior.png"
        alt="exterior image"
        style={{
          gridArea: "1/1",
          maxHeight: "100vh"
        }}
      />
      <Row
        justify={"center"}
        align={"middle"}
        style={{ height: "100vh", gridArea: "1/1", width: "100vw" }}
      >
        <Card style={{
          maxWidth: 600,
          width: 450,
          backgroundColor: "rgba(255,255,255,0.9)",
          paddingTop: 30
        }}>
          <Form onFinish={handleLogin}>
            <Form.Item
              name={"email"}
              rules={[
                {
                  type: "email",
                  message: "Veuillez saisir un e-mail valide!"
                },
                { required: true, message: "Veuillez saisir votre e-mail!" }
              ]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>
            <Button type={"primary"} htmlType={"submit"}>Connexion</Button>
          </Form>
        </Card>
      </Row>
    </div>
  )
}
