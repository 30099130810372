import * as React from "react"
import Seo from "../components/seo"
import Login from "../components/login"

const IndexPage = () => (
  <div>
    <Seo title="Accueil" />
    <Login />
  </div>
)

export default IndexPage
